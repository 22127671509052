import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import axios from "axios"
import 'element-ui/lib/theme-chalk/index.css';
import global from './utils/global'
import dictInfo from './utils/dictInfo'
import { imgUrl,processName,occlusionCard,occlusionToPhone } from './utils/imgUrl.js'
import './assets/icon/iconfont.css'
import pinyin from 'js-pinyin'
import VueAMap from 'vue-amap'
import '../src/assets/font/font.css';
import '../src/assets/font/font1.css';
import VueLazyload from 'vue-lazyload'

import tim from "./utils/tim.js";

// // 从v2.11.2起，SDK 支持了 WebSocket，推荐接入；v2.10.2及以下版本，使用 HTTP
// import TIM from 'tim-js-sdk'
// import TIMUploadPlugin from 'tim-upload-plugin'
// let options = {
//   SDKAppID: 1400737388, // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
// }
// // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
// let tim = TIM.create(options) // SDK 实例通常用 tim 表示
// // 设置 SDK 日志输出级别，详细分级请参见 setLogLevel https://web.sdk.qcloud.com/im/doc/zh-cn/SDK.html#setLogLevel 接口的说明</a>
// tim.setLogLevel(0) // 普通级别，日志量较多，接入时建议使用
// // tim.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用

// // 注册腾讯云即时通信 IM 上传插件
// tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin })


Vue.use(VueLazyload)
Vue.use(ElementUI);
Vue.use(pinyin);
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: '5e8d3e02530f5817d22a2bcdcd90bbd6',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor',"AMap.Geolocation","AMap.Geocoder"],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});
window._AMapSecurityConfig = {
  securityJsCode: "57604b5136c65a7020cfe02d68e2f3b1"
};
Vue.prototype.$axios = axios
Vue.config.productionTip = false
// import futongtitle from './components/fuyong/fuyongtitle'
// Vue.component("futongtitle",futongtitle)
Vue.prototype.$global = global
Vue.prototype.$dictInfo = dictInfo
Vue.prototype.$imgUrl = imgUrl
Vue.prototype.$processName = processName
Vue.prototype.$occlusionCard = occlusionCard
Vue.prototype.$occlusionToPhone = occlusionToPhone
Vue.prototype.$tim = tim

new Vue({
  router,
  global,
  dictInfo,
  store,
  render: h => h(App)
}).$mount('#app')

// 视频播放组件
import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'
Vue.use(vueMiniPlayer)
