<template>
  <div class="box-compatibility">
    <img class="logo" src="@/assets/home/logo.png" alt="">
    <h2 class="title">甄工匠</h2>
    <p class="title-text">解决新型工业化人才评价痛点</p>
    <img src="./bg-img.png" alt="">
    <div class="tips">
      为了更好的浏览体验，请用电脑浏览本网站或下载APP!
    </div>
    <div class="qrCode">
      <div>
        <img class="img-software" src="http://121.36.46.11:9000/app/android.png" alt="">
        <p>下载APP</p>
      </div>
      <div>
        <img class="img-software" src="http://121.36.46.11:9000/app/uniapp-xcx.jpg" alt="">
        <p>查看小程序</p>
      </div>
    </div>
  </div>
</template>

<script>
// import compatibility from "@/components/compatibility/compatibility.vue";
export default {
  // components: {
  //   compatibility
  // },
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
        // 判断是否是手机端查看
    mobile() {
      let userAgentInfo = navigator.userAgent;
      let mobileAgents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      //根据userAgent判断是否是手机
      for (let v = 0; v < mobileAgents.length; v++) {
        if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
          this.isMobile = true;
          break;
        }
      }
      let screen_width = window.screen.width;
      let screen_height = window.screen.height;
      //根据屏幕分辨率判断是否是手机
      if (screen_width > 325 && screen_height < 750) {
        this.isMobile = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
p{
  margin: 0;
  padding: 0;
}
  .box-compatibility{
    width: 100%;
    height: 100vh;
    color: #0D9FF9;
    text-align: center;
    background: linear-gradient(to bottom, #BAE5FF, #FFFFFF);
    box-sizing: border-box;
    padding:60px 0 10px;
    .logo{
      width: 67px;
      height: 72px;
    }
    .title{
      letter-spacing: 5px;
    }
    .title-text{
      margin-bottom: 50px;
    }
    .tips{
      margin-top: 50px;
      font-size: 14px;
      color: #666;
    }
    .qrCode{
      display: flex;
      justify-content: space-around;
      color: #000;
    }
  }
  .img-software{
    width: 108px;
    height: 108px;
    margin: 20px;
  }
</style>
