import request from "@/api/request"

// 获取认证信息
export function getJobUserAuth(data) {
  return request({
    method: 'get',
    url: '/jobUser/jobAuthUser/getJobUserAuth',
    params:data
  })
}

// 添加求职信息
export function jobInfoAdd(data) {
  return request({
    method: 'post',
    url: '/job/jobInfo/add',
    data:data
  })
}

// 根据id查询求职信息
export function jobInfoDetail(data) {
  return request({
    method: 'get',
    // url: '/job/jobInfo/detail',
    url: '/job/oauth/jobInfo/detail',
    params:data
  })
}

// 个人中心分页查询求职信息
export function jobInfoPage(data) {
  return request({
    method: 'get',
    url: '/job/jobInfo/page',
    params:data
  })
}

// 分页查询搜索求职信息
export function queryJobInfoPage(data) {
  return request({
    method: 'get',
    // url: '/job/jobInfo/queryJobInfoPage',
    url: '/job/oauth/jobInfo/queryJobInfoPage',
    params:data
  })
}

// 编辑求职信息
export function jobInfoEdit(data) {
  return request({
    method: 'post',
    url: '/job/jobInfo/edit',
    data:data
  })
}

// 删除求职信息
export function jobInfoDelete(data) {
  return request({
    method: 'post',
    url: '/job/jobInfo/delete',
    data:data
  })
}
