<template>
  <div id="app" v-if="!isMobile">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>|
      <router-link to="/renzheng">renzheng</router-link>
    </div> -->
    <router-view/>
  </div>
  <div id="app" v-else>
    <compatibility></compatibility>
  </div>
</template>


<script>
import compatibility from "@/components/compatibility/compatibility.vue";
export default {
  components: {
    compatibility
  },
  data() {
    return {
      isMobile: false
    };
  },
  created() {
    this.mobile()
  },
  mounted() {
  },
  methods: {
        // 判断是否是手机端查看
    mobile() {
      let userAgentInfo = navigator.userAgent;
      let mobileAgents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      //根据userAgent判断是否是手机
      for (let v = 0; v < mobileAgents.length; v++) {
        if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
          this.isMobile = true;
          break;
        }
      }
      let screen_width = window.screen.width;
      let screen_height = window.screen.height;
      //根据屏幕分辨率判断是否是手机
      if (screen_width > 325 && screen_height < 750) {
        this.isMobile = true;
      }
    }
  }
}
</script>
<style type="text/css">
body {
  margin:0;
  padding: 0;
  bottom: 0;
  background-color: #EFF1F4;

}
</style>
<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }

// 高德地图去掉logo
.amap-box {
  .amap-logo,
  .amap-copyright {
    display: none !important;
  }
}
</style>
