import global from '@/utils/global'
import { jobPostType } from "@/api/home/home";

var dict = JSON.parse(localStorage.getItem("dict"));
var showProvinceList = JSON.parse(localStorage.getItem("provincialList"));
var showCityList = JSON.parse(localStorage.getItem("cityList"));
var showAreaList = JSON.parse(localStorage.getItem("areaList"));

var orgType = '';
var jobEdu = '';
var jobExp = '';
var jobGm = '';
var jobPay = '';
if(dict){
    orgType = dict ? dict.filter((i) => i.code == "job_org_type")[0].children : '';
    jobEdu = dict ? dict.filter((i) => i.code == "job_edu")[0].children : '';
    jobExp = dict ? dict.filter((i) => i.code == "job_exp")[0].children : '';
    jobGm = dict ? dict.filter((i) => i.code == "job_gm")[0].children : '';
    jobPay = dict ? dict.filter((i) => i.code == "job_pay")[0].children : '';
}else{
  global.getDictInfo().then(res=>{
    dict = res.data
    orgType = dict.filter((i) => i.code == "job_org_type")[0].children ;
    jobEdu = dict.filter((i) => i.code == "job_edu")[0].children;
    jobExp = dict.filter((i) => i.code == "job_exp")[0].children;
    jobGm = dict.filter((i) => i.code == "job_gm")[0].children;
    jobPay = dict.filter((i) => i.code == "job_pay")[0].children;
  })
}
if(!showProvinceList || showProvinceList.length <= 0){
  global.getProvincialInfo().then(res=>{
    showProvinceList = res.data
  })
}
if(!showCityList || showCityList.length <= 0){
  global.getCityInfo().then(res=>{
    showCityList = res.data
  })
}

if(!showAreaList || showAreaList.length <= 0){
  global.getAreaInfo().then(res=>{
    showAreaList = res.data
  })
}

// 显示企业类型
function getOrgType(code) {
  if(code && orgType){
    return orgType.filter((i) => i.code == code)[0].name;
  }else{
    return '';
  }
}
// 显示学历
function getEdu(code) {
  if(code && jobEdu){
    return jobEdu.filter((i) => i.code == code)[0].name;
  }else{
    return '';
  }
}
// 显示个人经验
function getExp(code) {
  if(code && jobExp){
    return jobExp.filter((i) => i.code == code)[0].name;
  }else{
    return '';
  }
}

// 显示公司人数
function getGm(code) {
  if(code && jobGm){
    return jobGm.filter((i) => i.code == code)[0].name;
  }else{
    return '';
  }
}
// 显示薪资
function getPay(code) {
  if(code && jobPay){
    return jobPay.filter((i) => i.code == code)[0].name;
  }else{
    return '';
  }
}

// 回显省份
function getProvinceForId(id) {
  if(parseFloat(id) && showProvinceList){
  return showProvinceList.filter((i) => i.PROVINCE_CODE == id)[0]
    .SHORT_NAME;
  }else{
    return '湖北'
  }
}

// 回显城市
function getCityForId(id) {
  if(parseFloat(id) && showCityList){
    return showCityList.filter((i) => i.CITY_CODE == id)[0].SHORT_NAME;
  }else{
    return '武汉'
  }
}

//回显区域
function getAreaForId(id) {
  if(parseFloat(id) && showAreaList){
    return showAreaList.filter((i) => i.CITY_CODE == id)[0].SHORT_NAME;
  }else{
    return '武汉'
  }
}

// 回显工种
var orgList = ''
getOrg().then(res=>{
  orgList = hasChildren(res.data[0].children)
})
function getSomething() {
  return new Promise(resolve => {
    let params = {
      code: "trade_type",
    };
    jobPostType(params).then(res => {
             resolve(res)
        })
   })
}
async function getOrg() {
  let res = await getSomething();
  return res
}

function hasChildren(list) {
  list.forEach((item) => {
    item.name = item.title;
    item.child = item.children;
    item.selected = false;
    if (item.child.length > 0) {
      hasChildren(item.child);
    } else {
      item.child = [];
    }
  });
  return list;
}
function getExpectPost(id) {
  let data = getExpectPostForId(orgList,id);
  return data;
}
function getExpectPostForId(list,id) {
  var title = "";
  for (let i = 0; i < list.length; i++) {
    if (list[i].id == id) {
      title = list[i].title;
      break;
    } else if (list[i].children && list[i].children.length > 0) {
      title = getExpectPostForId(list[i].children, id);
      if (title) return title;
    }
  }
  return title;
}

// 根据公司行业id回显  递归找父级id
function getTradeType(str) {
  let newStr = str.split(',')
  let arr = []
  if (orgList.length > 0) {
    newStr.forEach(i => {
      let ids = getOrgTypeForTree(orgList, i)
      if (ids.length > 0) {
        arr.push(ids)
      }
    })
  }
  let strInfo = arr.join('，')
  return strInfo
}

function getOrgTypeForTree(list, id) {
  var ids = []
  for (let i = 0; i < list.length; i++) {
    if (list[i].id == id) {
      ids.push(list[i].title)
      break
    } else if (list[i].children && list[i].children.length > 0) {
      ids = getOrgTypeForTree(list[i].children, id)
      if (ids.length > 0) return ids
    }
  }
  return ids
}

export default {
  getProvinceForId,
  getCityForId,
  getAreaForId,
  getOrgType,
  getEdu,
  getExp,
  getGm,
  getPay,
  getExpectPost,
  getTradeType
};
