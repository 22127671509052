import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const HAS_ORG_LOGIN = localStorage.getItem('hasOrgLogin')
const peopleList = JSON.parse(localStorage.getItem('TIM-peopleList'))
const operateList = JSON.parse(localStorage.getItem('TIM-operateList'))
const orgList = JSON.parse(localStorage.getItem('TIM-orgList'))
const wordList = JSON.parse(localStorage.getItem('TIM-wordList'))
const TIMUserId = localStorage.getItem('TIM-userId')
const wsMessage = JSON.parse(localStorage.getItem('wsMessage'))

export default new Vuex.Store({
  state: {
    dict: [{}],
    userInfo: {},
    showw: 3,
    // 是否为企业登录
		hasOrgLogin: HAS_ORG_LOGIN,
    peopleList: peopleList, //会话列表
    operateList: operateList, //运营系统消息列表
    orgList: orgList, //企业系统消息列表
    wordList: wordList, //单个对话消息
    TIMUserId: TIMUserId, //对话人id
    isRedPoint: false, //是否有消息小红点
    isOperatePoint: false, //是否有系统消息小红点
    isOrgRedPoint: false, //是否有应聘消息小红点
    wsMessage: wsMessage ? wsMessage : []
  },
  getters: {
    sysMessage: state => {
      return state.wsMessage.filter(i => i.payload_totype == 'sys')
    },
    jobMessage: state => {
      return state.wsMessage.filter(i => i.payload_totype == 'job')
    },
    msCount: state => {
      return state.sysMessage.filter(i => !i.read).length
    }
  },
  mutations: {
    set_peopleList(state,peopleList) {
      state.peopleList = peopleList
      localStorage.setItem('TIM-peopleList',JSON.stringify(peopleList))
    },
    set_operateList(state,operateList) {
      state.operateList = operateList
      localStorage.setItem('TIM-operateList',JSON.stringify(operateList))
    },
    set_orgList(state,orgList) {
      state.orgList = orgList
      localStorage.setItem('TIM-orgList',JSON.stringify(orgList))
    },
    set_wordList(state,wordList) {
      state.wordList = wordList
      localStorage.setItem('TIM-wordList',JSON.stringify(peopleList))
    },
    set_TIMUserId(state,TIMUserId) {
      state.TIMUserId = TIMUserId
      localStorage.setItem('TIM-userId',TIMUserId)
    },
    set_isRedPoint(state,isRedPoint) {
      state.isRedPoint =  isRedPoint
    },
    set_isOperatePoint(state,isOperatePoint) {
      state.isOperatePoint =  isOperatePoint
    },
    set_isOrgRedPoint(state,isOrgRedPoint) {
      state.isOrgRedPoint =  isOrgRedPoint
    },
    SET_HASORGLOGIN(state, hasOrgLogin) {
			state.hasOrgLogin = hasOrgLogin;
      localStorage.setItem('hasOrgLogin',hasOrgLogin)
		},
    initUser(state) {
      let dict = localStorage.getItem('dict')
      if (dict) {
        dict = JSON.parse(dict)
      }
    },
    //登录后保存用户信息
    setMsg(state, dict) {
      state.dict = dict
      //持久化存储 ===>把对象转换成字符串
      localStorage.setItem('dict', JSON.stringify(dict))
    },
    set_wsMessage(state, wsMessage) {
      state.wsMessage = wsMessage
      localStorage.setItem('wsMessage', JSON.stringify(wsMessage))
    }
  },
  actions: {},
  modules: {},
})
