var dict = JSON.parse(localStorage.getItem("dict"));

function imgUrl(url) {
    if (url && url.includes('http')) {
		let str = url.replace(/job-app/g, 'job-app/mini');
		return str
	} else {
		return require('../assets/home/affiliated-img.png')
	}
}

function processName(str){
	if(null != str && str != undefined){
			let star = '' //存放名字中间的*
			//名字是两位的就取姓名首位+*
			if(str.length <= 2){
					return str.substring(0,1)+"*"  ;
			} else {
					for (var i = 0; i < str.length-2; i++) {
							star= star + '*'
					}
					return str.substring(0,1)+ star + str.substring(str.length-1,str.length);
			}
	}else{
		return '***'
	}
}

function base(data, frontShow, afterShow) {
	let dataLengh = data.length;
	if (dataLengh > frontShow + afterShow) {
		let obscuringStar = '*';
		// 计算中间星星数
		for (let i = 0; i < dataLengh - frontShow - afterShow; i++) {
			obscuringStar += '*';
		}
		return data.substring(0, frontShow) + obscuringStar + data.substring(data.length - afterShow, data.length);
	} else {
		return '—'; // 不规范时返回'-'
	}
}

// 身份证遮盖长度
function occlusionCard(data) {
	if(data){
		return base(data, 6, 2);
	}else{
		return '—';
	}

}
function occlusionToPhone(data) {
	if(data){
		return base(data, 4, 2);
	}else{
		return '—';
	}
}

export { imgUrl,processName,occlusionCard,occlusionToPhone};
