<template>
  <div class="go-box">
    <div class="do-go">
      <div class="boxs">
        <div class="bos-left">
          <div class="logoBox">
            <img src="@/assets/login/logo.png" alt="" />
          </div>
          <div class="sloganBox">
            <span style="font-size: 18px; color: #666"
              >解决新型工业化人才评价痛点</span
            >
            <div class="slogans">
              <i class="iconfont icon-line_qukuailian"></i>
              <span>运用区块链技术,构建新型工业化人才评价体系</span>
            </div>
            <div class="slogans">
              <i class="iconfont icon-zhineng"></i>
              <span>让企业人力资源管理服务变得更加智能高效</span>
            </div>
          </div>
        </div>
        <div class="bos-rit">
          <div class="box-p">
            <div class="left" @click="zhao">
              <div class="box-bos">
                <div class="zhao">
                  <img
                    class="user-icon"
                    src="../../img/userIcon.png"
                    alt=""
                  />我要找工作
                </div>
                <img
                  src="../../img/图层 1.png"
                  alt=""
                  :class="zhaoq == 1 ? 'imgs' : 'img'"
                />
              </div>
            </div>
            <div class="left rit" @click="zhaopin">
              <div class="box-bos">
                <img
                  src="../../img/图层2.png"
                  alt=""
                  :class="zhaoq == 2 ? 'imgs' : 'img'"
                />
                <div class="zhao">
                  <img
                    class="com-icon"
                    src="../../img/comIcon.png"
                    alt=""
                  />我要招聘
                </div>
              </div>
            </div>
          </div>
          <!-- 输入框 -->
          <div class="ipt">
            <div class="point-out">
              您现在使用的是个人登录,企业用户请选择
              <span style="color: #ff6a00; font-size: 14px">我要招聘。</span>
            </div>
            <div class="register" v-show="show">
              <div :class="xian == 1 ? 'denglu1' : 'denglu'" @click="denglu">
                登录账号
                <!-- <div class="xian" v-if="xian == 1 ? true : false"></div> -->
              </div>
              <div :class="xian == 2 ? 'car1' : 'car'" @click="car">
                手机号登录
                <!-- <div class="xian" v-if="xian == 2 ? true : false"></div> -->
              </div>
            </div>

            <div class="register" v-show="shows">
              <div :class="xian == 1 ? 'denglu1' : 'denglu'" @click="denglu">
                招聘账号
                <div class="xian" v-if="xian == 1 ? true : false"></div>
              </div>
              <div :class="xian == 2 ? 'car1' : 'car'" @click="car">
                手机号登录
                <div class="xian" v-if="xian == 2 ? true : false"></div>
              </div>
            </div>

            <!-- 我要找工作 -->
            <form v-if="show" :model="form" id="loginFrom">
              <div class="ipts">
                <div class="ipt-box">
                  <input
                    v-if="xian == 1"
                    type="text"
                    placeholder="请输入账号"
                    v-model="form.account"
                    autocomplete
                    class="shuru1"
                  />
                  <input
                    v-else
                    type="text"
                    placeholder="请输入手机号"
                    v-model="data.phone"
                    autocomplete
                    class="shuru1"
                  />

                  <div class="yanzheng" v-if="dialogVisible == true">
                    请规范输入
                  </div>
                  <div>
                    <div v-if="xian == 1" class="login-info">
                      <input
                        class="shuru"
                        :type="pwdType"
                        maxlength="20"
                        placeholder="请输入密码"
                        v-model="form.password"
                        autocomplete
                      />
                      <img
                        class="login-eye"
                        :src="seen ? openEye : onopenEye"
                        alt
                        @click="changeType()"
                      />
                    </div>
                    <div v-else class="verification-box">
                      <input
                        class="shuru2"
                        type="text"
                        v-model="data.code"
                        maxlength="10"
                        placeholder="请输入验证码"
                      />

                      <div class="verification-code">
                        <!-- v-show="result == null" -->
                        <span
                          v-if="!codeFlag"
                          slot="suffix"
                          @click="getCode"
                          style="
                            padding: 0 10px;
                            cursor: pointer;
                            color: #029aff;
                          "
                          ><el-divider direction="vertical"></el-divider
                          >获取验证码</span
                        >
                        <!-- v-show="result > 0 && result" -->
                        <span
                          v-else
                          slot="suffix"
                          style="
                            padding: 0 10px;
                            cursor: pointer;
                            color: #029aff;
                          "
                          ><el-divider direction="vertical"></el-divider
                          >{{ authTime }}</span
                        >
                      </div>
                    </div>
                    <div class="yanzheng" v-if="dialogVisibl == true">
                      请规范输入
                    </div>
                  </div>
                  <div class="mima" @click="toForgetPwd">
                    忘记密码？
                    <!-- <div class="xiaxian"></div> -->
                  </div>
                  <!-- 按钮 -->
                  <div class="btn">
                    <div class="go" @click="_login">登录</div>
                  </div>
                  <!-- 注册 -->
                  <div class="zhuce">
                    还没有账号？
                    <div class="gozhu" @click="gozhu">立即注册</div>
                  </div>
                </div>
              </div>
            </form>
            <!-- 我要招聘 -->
            <form v-if="shows">
              <div class="ipts">
                <div class="ipt-box">
                  <input
                    type="text"
                    :placeholder="xian == 1 ? '请输入账号' : '请输入手机号'"
                    autocomplete
                    class="shuru1"
                  />
                  <div>
                    <input
                      class="shuru"
                      type="password"
                      placeholder="请输入密码"
                      autocomplete
                    />
                  </div>
                  <div class="mima">
                    忘记密码？
                    <div class="xiaxian"></div>
                  </div>
                  <!-- 按钮 -->
                  <div class="btn">
                    <div class="go">登录</div>
                  </div>
                  <!-- 注册 -->
                  <div class="zhuce">
                    还没有账号？
                    <div class="gozhu" @click="gozhu">立即注册</div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 1 页面引入mapState
import { mapState } from 'vuex'
import { login, sendSmsCode } from '@/api/login/login'
import { mapMutations } from 'vuex'
import { getDict } from '@/api/system/system'
import { setToken, removeToken } from '@/utils/myauth'
import { getLoginUser } from '@/api/login/login'
import { getProvincial, getCity, getArea, getUserMsg } from '@/api/index/index'
import { getOrg } from '@/api/certification/certification'
// import tim from "@/utils/tim.js";
import genTestUserSig from '@/utils/debug/GenerateTestUserSig.js'
import { detailTeam } from '@/api/team/team'
import { getJobUserAuth } from '@/api/jobInformation/jobInformation'
export default {
  data() {
    return {
      dialogVisible: false,
      dialogVisibl: false,
      xian: 1,
      isSelected: true,
      isSelecteds: false,
      show: true,
      shows: false,
      zhaoq: 1,
      form: { account: '', password: '' },
      data: { phone: '', code: '' },
      codeFlag: false,
      authTime: 59,
      userInfo: '',
      pwdType: 'password',
      openEye: require('@/assets/login/openEye.png'),
      onopenEye: require('@/assets/login/onopenEye.png'),
      seen: false,
    }
  },
  computed: {
    ...mapState(['dict']),
  },
  created() {
    this._getDict()
  },
  mounted() {},
  methods: {
    ...mapMutations(['setMsg']),
    _login() {
      if (this.xian == 1) {
        if (this.form.account.length < 2) {
          this.dialogVisible = true
        } else {
          this.dialogVisible = false
        }
        if (this.form.password.length < 6) {
          this.dialogVisibl = true
        } else {
          this.dialogVisibl = false
        }
      } else {
        if (this.data.phone.length < 2) {
          this.dialogVisible = true
        } else {
          this.dialogVisible = false
        }
        if (this.data.code < 6) {
          this.dialogVisibl = true
        } else {
          this.dialogVisibl = false
        }
      }
      console.log('login')
      removeToken()
      login(this.xian == 1 ? this.form : this.data).then(res => {
        console.log('login', res)
        if (res.success) {
          setToken(res.data)
          // getDict({}).then(res => {
          //   console.log('getDict', res)
          //   if (res.code == 200 && res.message === '请求成功') {
          //     localStorage.setItem('dict', JSON.stringify(res.data))
          //     // this.setMsg(res.data)
          //     console.log('99', res.data)
          //   }
          // })

          getOrg().then(res1 => {
            console.log(res1, '企业认证信息')
            if (res1.data) {
              localStorage.setItem('orgId', res1.data.id)
              this.$store.commit('SET_HASORGLOGIN', true)
            }
          })

          getLoginUser().then(async res1 => {
            if (res1.success) {
              // 即时通讯IM初始化
              console.log(res1, 'getLoginUser')
              this.userInfo = res1.data
              console.log(this.userInfo)
              localStorage.setItem(
                'userImg',
                this.userInfo.avatar ? this.userInfo.avatar : ''
              )
              let ech = await getJobUserAuth()
              let userAuth = ech.data
              let info = await detailTeam({ jobUserId: res1.data.id })
              let userTeam = info.data
              if (userAuth != null) {
                localStorage.setItem('account', userAuth.name)
              } else if (userTeam != null && userTeam.auditStatus == 2) {
                localStorage.setItem('account', userTeam.name)
              } else {
                localStorage.setItem('account', this.userInfo.account)
              }
              this.timCreate()
              this._getUserMsg()
            }
            let data = []
            localStorage.setItem('readInfo', JSON.stringify(data)) //人才查看记录
            localStorage.setItem('userId', res1.data.id)
            this.$router.push('/prise')
          })
          getProvincial().then(res1 => {
            localStorage.setItem('provincialList', JSON.stringify(res1.data))
          })
          getCity().then(res1 => {
            localStorage.setItem('cityList', JSON.stringify(res1.data))
          })
          getArea().then(res1 => {
            localStorage.setItem('areaList', JSON.stringify(res1.data))
          })
        } else {
          // 提示弹窗
          // this.dialogVisible = 1
          this.$message.error(res.message)
        }
      })
    },
    // 字典
    _getDict() {
      getDict({}).then(res => {
        if (res.code == 200 && res.message === '请求成功') {
          localStorage.setItem('dict', JSON.stringify(res.data))
        }
      })
    },
    // 忘记密码
    toForgetPwd() {
      this.$router.push('/forgetPwd')
    },
    gozhu() {
      this.$router.push('/register')
    },
    denglu() {
      this.xian = 1
      if (this.xian == 1) {
        this.isSelected = true
      }
      this.dialogVisible = false
      this.dialogVisibl = false
    },
    car() {
      this.xian = 2
      this.dialogVisible = false
      this.dialogVisibl = false
    },
    // 切换招聘
    zhaopin() {
      // window.location="http://120.46.176.211:9001/user/login"
      // window.location = "http://120.46.199.158:9001/ /user/login";
      window.location = 'http://enterprise.zhengongjiang.com/user/login'
      /*
      this.zhaoq = 2
      this.shows = true
      this.show = false
       */
    },
    // 切换个人工作身份
    zhao() {
      this.zhaoq = 1
      this.show = true
      this.shows = false
    },
    // IM初始化
    timCreate() {
      let that = this
      // const userID = this.userInfo.account
      const userID = this.userInfo.id
      const userSig = genTestUserSig(userID).userSig
      let promise = this.$tim.login({ userID: userID, userSig: userSig })
      promise
        .then(res => {
          console.log(res, '登录成功')
        })
        .catch(err => {
          console.log(err, '登录失败')
        })
    },
    // 获取验证码
    getCode() {
      let regTest =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!this.data.phone) {
        this.$message.error('请输入手机号码')
        return
      } else {
        if (!regTest.test(this.data.phone)) {
          this.$message.error('手机号码格式不正确')
          return
        }
      }
      this.codeFlag = true
      var authTimetimer = setInterval(() => {
        this.authTime--
        if (this.authTime <= 0) {
          this.codeFlag = false
          this.authTime = 59
          clearInterval(authTimetimer)
        }
      }, 1000)
      sendSmsCode({ phone: this.data.phone }).then(res => {
        console.log(res)
        // if (res.success) {
        // this.form.vCode = res.data.code
        // }
      })
    },
    changeType() {
      this.pwdType = this.pwdType === 'password' ? 'text' : 'password'
      this.seen = !this.seen
    },
    _getUserMsg() {
      getUserMsg({ userId: this.userInfo.id }).then(res => {
        console.log(res, '消息')
        if(res.data) {
          let data = res.data.msg
          let wsMessage = this.$store.state.wsMessage
          wsMessage = [...data,...wsMessage]
          this.$store.commit('set_wsMessage', wsMessage)
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
.point-out {
  width: 80%;
  text-align: center;
  margin: 10px auto;
  background: rgba(255, 195, 0, 0.04);
  color: #ff9f40;
  font-size: 12px;
}
.yanzheng {
  color: rgb(246, 18, 18);
}
::v--deep .el-dialog__header {
  margin-left: 120px;
}
body {
  margin: 0px;
}
::v-deep .el-dialog {
  width: 400px;
  .tis {
    margin-left: 130px;
  }
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.go-box {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: url(../../img/beihua.png) repeat;
  background-size: 100% 100%;
  .do-go {
    min-width: 1920px;
    min-height: 1080px;
    .boxs {
      display: flex;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 877px;
      height: 550px;
      .bos-left {
        width: 318px;
        height: 100%;
        background-color: rgba($color: #f7fcff, $alpha: 0.9);
        .logoBox {
          height: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 140px;
            height: 140px;
          }
        }

        .sloganBox {
          height: 50%;
          padding: 0 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 14px;
          color: #999;
          justify-content: space-between;
          .slogans {
            display: flex;
            align-items: center;
            i {
              font-size: 36px;
              margin-right: 20px;
            }
          }
          .slogans:nth-last-child(1) {
            margin-bottom: 70px;
          }
        }
      }

      .bos-rit {
        width: 559px;
        height: 550px;
        .left {
          cursor: pointer;
        }
        .box-p {
          display: flex;
          .rit {
            margin-left: 30px;
            .box-bos {
              position: relative;
              .img {
                position: absolute;
                top: -4.1%;
                left: -12.4%;
                z-index: 10;
                height: 81px;
              }
              .imgs {
                position: absolute;
                top: -1.1%;
                left: -12.4%;
                z-index: 15;
                height: 81px;
              }

              .zhao {
                margin-left: 47px;
                z-index: 11;
                width: 210px;
                // background: #029aff;
                background: #ffffff;
                border-radius: 0 5px 0 0;
                // color: rgba(255, 255, 255, 1);
                color: rgba(2, 154, 255, 1);
                font-size: 20px;
                text-align: center;
                line-height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        .box-bos {
          display: flex;

          .zhao {
            position: relative;
            width: 210px;
            height: 80px;
            border-radius: 5px 0 0 0;
            // background: #ffffff;
            background: #029aff;
            color: rgba(255, 255, 255, 1);
            // color: rgba(2, 154, 255, 1);
            font-size: 20px;
            text-align: center;
            line-height: 80px;
          }
          .box-bos1 {
            background: #029aff;
          }
          .img {
            // top: -0.7%;
            left: 60%;
            position: absolute;
            display: block;
            height: 84px;
            z-index: 11;
            transform: rotate(-90deg);
          }
          .imgs {
            // top: -0.7%;
            left: 60%;
            position: absolute;
            display: block;
            height: 79px;
            z-index: 15;
            transform: rotate(90deg);
          }
        }
      }
      .ipt {
        width: 497px;
        height: 470px;
        background: #ffffff;
        overflow: hidden;
        .register {
          display: flex;
          .denglu {
            margin-left: 20%;
            margin-top: 3%;
            width: 80px;
            height: 24px;
            text-align: center;
            color: rgba(153, 153, 153, 1);
            font-size: 16px;
            cursor: pointer;
          }
          .denglu1 {
            margin-left: 20%;
            margin-top: 3%;
            width: 80px;
            height: 24px;
            text-align: center;
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
            cursor: pointer;
          }
          .car {
            margin-left: 25%;
            margin-top: 3%;
            width: 80px;
            height: 24px;
            text-align: center;
            color: rgba(153, 153, 153, 1);
            font-size: 16px;
            cursor: pointer;
          }
          .car1 {
            margin-left: 25%;
            margin-top: 3%;
            width: 80px;
            height: 24px;
            text-align: center;
            color: rgba(51, 51, 51, 1);
            font-size: 16px;
            cursor: pointer;
          }

          .xian {
            margin-top: 15px;
            width: 80px;
            height: 4px;
            background: rgba(2, 154, 255, 1);
          }
        }
        .ipts {
          margin-top: 7%;

          .ipt-box {
            width: 400px;
            margin-left: 10%;
            input[type='text']:focus {
              outline: none;
              border: 1px solid rgba(2, 154, 255, 1);
            }
            input[type='password']:focus {
              outline: none;
              border: 1px solid rgba(2, 154, 255, 1);
            }
            .shuru {
              margin-top: 18px;
              input: {
                background: rgba(255, 255, 255, 1)
                  url(../../img/loging_icon_mima_normal.png) 23px no-repeat;
              }
            }
            .shuru2 {
              margin-top: 18px;
            }
            .mima {
              width: 74px;
              height: 22px;
              color: rgba(102, 102, 102, 1);
              font-size: 14px;
              margin-left: 340px;
              height: 7px;
              cursor: pointer;
              text-decoration: underline;
              margin-top: 10px;
            }
            // .xiaxian {
            //   width: 74px;
            //   height: 1px;
            //   background: rgba(102, 102, 102, 1);
            // }
            .btn {
              width: 400px;
              height: 52px;
              background: rgba(2, 154, 255, 1);
              border-radius: 5px;
              margin: 40px 70px 45px 0px;
              text-align: center;
              line-height: 52px;
              font-size: 16px;
              color: rgba(255, 255, 255, 1);
              cursor: pointer;
            }
            .zhuce {
              display: flex;
              margin: 0 auto;
              width: 140px;
              height: 18px;
              color: rgb(152, 150, 150);
              font-size: 14px;
              line-height: 18px;
              cursor: pointer;
            }
            .gozhu {
              color: rgba(2, 154, 255, 1);
            }
          }

          .ipt-box input:nth-of-type(1) {
            height: 56px;
            width: 336px;
            padding-left: 60px;
            line-height: 56px;
            border-radius: 5px;
            border: 1px solid rgb(164, 159, 159);
            // background: rgba(255, 255, 255, 1)
            //   url(../../img/loging_icon_yonghu_normal.png) 23px no-repeat;
          }
          .ipt input:nth-of-type(2) {
            padding-left: 60px;
            height: 56px;
            width: 336px;
            line-height: 56px;
            border-radius: 5px;
            border: 1px solid rgb(158, 147, 147);
            // background: rgba(255, 255, 255, 1)
            //   url(../../img/loging_icon_mima_normal.png) 23px no-repeat;
          }
        }
      }
    }
  }
}
.shuru1 {
  background: rgba(255, 255, 255, 1)
    url(../../img/loging_icon_yonghu_normal.png) 23px no-repeat !important;
}
.shuru {
  background: rgba(255, 255, 255, 1) url(../../img/loging_icon_mima_normal.png)
    23px no-repeat !important;
}
.shuru2 {
  background: rgba(255, 255, 255, 1) url(../../img/loging_icon_yan_normal.png)
    23px no-repeat !important;
}
.verification-box {
  position: relative;
  .verification-code {
    position: absolute;
    right: 0;
    top: 50%;
  }
}
.user-icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}
.com-icon {
  width: 18px;
  height: 22px;
  margin-right: 10px;
}
.login-info {
  display: flex;
  position: relative;
  .login-eye {
    position: absolute;
    right: 10px;
    top: 37px;
    width: 25px;
    height: 25px;
  }
}
</style>
