import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import login from '../views/login/login.vue'
import { readAdd } from '@/api/index/index'
import genTestUserSig from '@/utils/debug/GenerateTestUserSig.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/login/register.vue'),
  },
  {
    path: '/forgetPwd',
    name: 'forgetPwd',
    component: () => import('../views/login/forgetPwd.vue'),
  },
  {
    path: '/prise',
    name: 'prise ',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/prise/index.vue'),
  },
  //企业认证
  {
    path: '/certification',
    name: 'certification',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/prise/certification/index.vue'
      ),
  },
    //班组认证
    {
      path: '/team',
      name: 'team',
      component: () =>
        import(
          /* webpackChunkName: "about" */ '../views/prise/team/index.vue'
        ),
    },
  // 认证详情
  {
    path: '/details',
    name: 'details',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/prise/details/index.vue'
      ),
  },
  //首页
  {
    path: '/home',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/home/index.vue'),
  },
  // 滚动首页
  {
    path: '/homeData',
    name: 'homeData',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/home/indexRolling.vue'),
  },
  // 新闻内容:

  // 求职信息
  {
    path: '/jobInformation',
    name: 'jobInformation',
    component: () => import('../views/jobInformation/jobInformation.vue'),
  },
  // 求职信息详情
  {
    path: '/jobInformationDetails',
    name: 'jobInformationDetails',
    component: () =>
      import(
        '../views/jobInformation/jobInformationDetails/jobInformationDetails.vue'
      ),
  },
  // 新闻详情
  {
    path: '/content',
    name: 'content',
    component: () => import('../views/home/content/content.vue'),
  },
  //新闻详情tab切换页面
  {
    path: '/newsx',
    name: 'newsx',
    component: () => import('../views/home/newsx/newsx.vue'),
  },

  //职位搜索
  {
    path: '/position',
    name: 'position',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/prise/position/position.vue'
      ),
  },
  //  职位详情
  {
    path: '/datum',
    name: 'datum',
    component: () => import('../views/prise/position/datum/datum.vue'),
  },
  //职位信息
  {
    path: '/information',
    name: 'information',
    component: () =>
      import('../views/prise/position/information/information.vue'),
  },
  //个人认证
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import('../views/home/authentication/authentication.vue'),
  },
  // 新闻
  {
    path: '/columnlist',
    name: 'columnlist',
    component: () => import('../views/home/columnlist/columnlist.vue'),
  },
  // 新闻栏目
  // {
  //   path: '/newscenter',
  //   name: 'newscenter',
  //   component: () => import('../views/home/newscenter/newscenter.vue'),
  // },
  // 学习
  {
    path: '/onlineLearning',
    name: 'onlineLearning',
    component: () => import('../views/onlineLearning/index.vue')
  },
  // 报名详情
  {
    path: '/registrationDetails',
    name: 'registrationDetails',
    component: () => import('../views/onlineLearning/registrationDetails.vue')
  },
    // 课程详情
  {
    path: '/courseDetails',
    name: 'courseDetails',
    component: () => import('../views/onlineLearning/courseDetails.vue')
  },
  {
    path: '/learn',
    name: 'learn',
    component: () => import('../views/onlineLearning/learn.vue')
  },
    // 考试界面
  {
    path: '/examinationView',
    name: 'examinationView',
    component: () => import('../views/onlineLearning/examinationView.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs/aboutUs.vue')
  },
  //个人中心
  {
    path: '/personalCenter',
    name: 'PersonalCenter',
    component: () => import('../views/personalCenter/personalCenter.vue'),
    redirect: { name: 'resumeManagement' },
    children: [
      {
        path: 'resumeManagement', // 简历管理
        name: 'resumeManagement',
        component: () =>
          import(
            '../views/personalCenter/resumeManagement/resumeManagement.vue'
          ),
      },
      {
        path: 'editResumeManagement', // 修改、添加简历
        name: 'editResumeManagement',
        component: () =>
          import(
            '../views/personalCenter/resumeManagement/editResumeManagement.vue'
          ),
      },
      {
        path: 'specialRecruitment', // 专场招聘
        name: 'specialRecruitment',
        component: () =>
          import(
            '../views/personalCenter/specialRecruitment/specialRecruitment.vue'
          ),
      },
      {
        path:'exam',  //我的考试
        name:'exam',
        component: () =>
          import('../views/personalCenter/exam/exam.vue'),
      },
      {
        path:'course',//我的课程
        name:'course',
        component: () =>
          import('../views/personalCenter/course/course.vue'),
      },
      {
        path:'training',//我的培训
        name:'training',
        component: () =>
          import('../views/personalCenter/course/training.vue'),
      },
      {
        path: 'myOrder', // 我的订单
        name: 'myOrder',
        component: () =>
          import('../views/personalCenter/myOrder/myOrder.vue'),
      },
      {
        path: 'resumeDelivery', // 简历投递
        name: 'resumeDelivery',
        component: () =>
          import('../views/personalCenter/resumeDelivery/resumeDelivery.vue'),
      },
      {
        path: 'myCollection', // 我的收藏
        name: 'myCollection',
        component: () =>
          import('../views/personalCenter/myCollection/myCollection.vue'),
      },
      {
        path: 'myJobInformation', // 求职信息
        name: 'myJobInformation',
        component: () =>
          import(
            '../views/personalCenter/myJobInformation/myJobInformation.vue'
          ),
      },
      {
        path: 'memberCenter', // 会员中心
        name: 'memberCenter',
        component: () =>
          import(
            '../views/personalCenter/memberCenter/memberCenter.vue'
          ),
      },
      {
        path: 'marketingCenter', // 营销服务
        name: 'marketingCenter',
        component: () =>
          import(
            '../views/personalCenter/marketingCenter/index.vue'
          ),
      },
      {
        path: 'addMyJobInformation', // 添加求职信息
        name: 'addMyJobInformation',
        component: () =>
          import(
            '../views/personalCenter/myJobInformation/addMyJobInformation.vue'
          ),
      },
      {
        path: 'teamInfo', // 班组信息
        name: 'teamInfo',
        component: () =>
          import(
            '../views/personalCenter/teamInfo/teamInfo.vue'
          ),
      },
      {
        path: 'editTeamInfo', // 修改修改班组信息
        name: 'editTeamInfo',
        component: () =>
          import(
            '../views/personalCenter/teamInfo/editTeamInfo.vue'
          ),
      },
      {
        path: 'personalSettings', // 个人设置
        name: 'personalSettings',
        component: () =>
          import(
            '../views/personalCenter/personalSettings/personalSettings.vue'
          ),
      },
      {
        path: 'editPersonalSettings', // 修改个人设置
        name: 'editPersonalSettings',
        component: () =>
          import(
            '../views/personalCenter/personalSettings/editPersonalSettings.vue'
          ),
      },
      {
        path: 'interviewManagement', // 面试管理
        name: 'interviewManagement',
        component: () =>
          import(
            '../views/personalCenter/interviewManagement/interviewManagement.vue'
          ),
      },
      {
        path: 'creditrating', //信用评价
        name: 'creditrating',
        component: () =>
          import('../views/personalCenter/creditrating/creditrating.vue'),
        children: [
          {
            path: '/biantu', //信用评价
            name: 'biantu',
            component: () =>
              import('../views/personalCenter/creditrating/biantu/biantu.vue'),
          },
        ],
      },
      {
        path: 'message', // 消息中心
        name: 'message',
        component: () =>
          import(
            '../views/personalCenter/message/message.vue'
          ),
      },


      {
        path: 'consumption', // 个人权益消费记录
        name: 'consumption',
        component: () =>
          import(
            '../views/personalCenter/consumption/consumption.vue'
          ),
      },

    ],
  },
  {
    path: '/creditEvaluation', // 个人/班组信用评价
    name: 'creditEvaluation',
    component: () =>
      import(
        '../views/personalCenter/creditrating/creditEvaluation.vue'
      ),
  },
  {
    path: '/creditExamination', // 个人/班组考试
    name: 'creditExamination',
    component: () =>
      import(
        '../views/personalCenter/creditrating/examinationView.vue'
      ),
  },
  {
    path: '/characterTestView', // 性格测试
    name: 'characterTestView',
    component: () =>
      import(
        '../views/personalCenter/creditrating/characterTestView.vue'
      ),
  },
    //信用评价
  {
    path: '/evaluation',
    name: 'evaluation',
    component: () => import('../views/evaluation/index.vue'),
    children: []
  },

  //优秀信用评价
  {
    path: '/ellent',
    name: 'ellent',
    component: () => import('../views/evaluation/ellent.vue'),
    children: []
  },
  {
    path: '/chainList', // 营销服务
    name: 'chainList',
    component: () =>
      import(
        '../views/evaluation/chainList.vue'
      ),
  },
  //个人信用评价详情
  {
    path: '/evaluationDetail',
    name: 'evaluationDetail',
    component: () => import('../views/evaluation/evaluationDetail.vue')
  },
  //班组信用评价详情
  {
    path: '/teamDetail',
    name: 'teamDetail',
    component: () => import('../views/evaluation/teamDetail.vue')
  },
  //劳务信用评价详情
  {
    path: '/labDetail',
    name: 'labDetail',
    component: () => import('../views/evaluation/labDetail.vue')
  },
  {
    path: '/newsCenter',
    name: 'newsCenter',
    component: () => import('../views/newsCenter/newsCenter.vue'),
  },
  // 招聘会讯
  {
    path: '/jobFair',
    name: 'jobFair',
    component: () => import('../views/jobFair/jobFair.vue'),
  },
  // 招聘会详情
  {
    path: '/jobFairDetails',
    name: 'jobFairDetails',
    component: () => import('../views/jobFairDetails/jobFairDetails.vue'),
  },
  {
    path: '/jobLineDetails',
    name: 'jobLineDetails',
    component: () => import('../views/jobFairDetails/jobLineDetails.vue'),
  },

  {
    path: '/enterpriseDetails', // 企业详情
    name: 'enterpriseDetails',
    component: () =>
      import(
        '../views/enterpriseDetails/enterpriseDetails.vue'
      ),
  },

  {
    path: '/notice',
    name: 'notice',
    component: () => import('../views/notice/notice.vue'),
  },

  {
    path: '/feedback',
    name: 'feedback',
    component: () => import('../views/feedback/feedback.vue'),
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0 })
})

router.beforeEach((to, from, next) => {
  let isToken = window.localStorage.getItem('Access-Token')
  if (isToken) {
    next()
  } else {
    if (!to.path.includes('/personalCenter')) {
      next()
    } else {
      next('/login')
    }
  }
})

router.afterEach((to,from) => {
  // 访问量
  readAdd()
  let isToken = window.localStorage.getItem('Access-Token')
  if(isToken) {
    const userID = localStorage.getItem('userId')
    const userSig = genTestUserSig(userID).userSig
    let promise = Vue.prototype.$tim.login({ userID: userID, userSig: userSig })
    promise
      .then(res => {
        console.log(res, '登录成功')
      })
      .catch(err => {
        console.log(err, '登录失败')
      })
  }
})

export default router
