import axios from "axios";
import router from '../router'

// import global from '../main'
// import global from '../utils/global'

import Vue from 'vue'
//引入qs
import qs from "qs";
//配置加载动画
import {
  Loading
} from 'element-ui';

import ElementUI from 'element-ui';
Vue.use(ElementUI);


// var global = Vue.prototype.$global


// 导入我们自己写的方法
import {
  getToken
} from '@/utils/myauth'

var host;
// process.env是Nodejs提供的一个API，它返回一个包含用户环境信息的对象。
// 通过NODE_ENV 判断当前环境是生产环境(production)还是开发环境(development) 自动切换不同域名
// 暂时开发环境和生产环境  所以域名暂时一样
if (process.env.NODE_ENV== "development") {
  //开发环境
  // console.log(Vue.prototype.$global,'Vue.prototype.$global');
    // host="http://120.46.176.211:9003/api"
    host="https://www.zhengongjiang.cn/api"
    // host="http://192.168.0.110:9003/api"

  // host="http://localhost:9003/api"

  // host="http://localhost:9003/api"
} else {
  // host="http://120.46.176.211:9003/api"
  host="https://www.zhengongjiang.cn/api"
}

//实例
var instance = axios.create({
  //基础地址
  baseURL: host,
  timeout:5*1000
})
var loading;
//请求拦截器
// var loading;
// 发送请求之前执行的处理
instance.interceptors.request.use(config => {
  // console.log(config);
    //qs处理post参数
/*   if (config.method.toLocaleLowerCase() == 'post') {
    config.data = qs.stringify(config.data);
  } */

  var token = getToken()

  if("/jobUser/jobUser/register" == config.url || config.url.includes('oauth')) {
  // if(config.url.includes('oauth')) {

  } else if(token && token!=='undefined') {
    config.headers['Authorization'] = 'Bearer ' + token
  }


  // // 判断token是否存在和是否需要token验证的路由
  // var token = getToken()
  // if ("/jobUser/jobUser/register"==config.url || config.url == '/main/oauth/rotationChart/list' || config.url == '/main/oauth/jobNews/page') {
  //   token=null
  // }
  // if (token && token!=='undefined') {
  //   // config.headers.Authorization = 'Bearer ' + getToken();
  //   config.headers['Authorization'] = 'Bearer ' + token
  // };
/*
  //开启加载loading....
  loading = Loading.service({
    lock: true,
    text: '努力加载中....',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  });

 */
  return config;
}, err => {
  console.log(err,'err')
  return Promise.reject(err)
})



//响应拦截器
// 数据返回之后所执行的响应拦截器
instance.interceptors.response.use(response => {
    // 当状态码等于200时
  if (response.status === 200) {
    const res = response.data;
    // 如果code是-1 说明用户注销或者token已经过期了 需要消除localstorage中的token
    if (res.code === -1) {
      clearHandler();
    }
    // loading.close();
     return res;
  }
  //关闭loading
  //  loading.close();
  // return res;

}, err => {
  // console.log(err,'err')
//  if (!err.response) {
//     //this.$message.error('服务器出现问题，请稍后重试！');
//     // 跳转到登录界面
//     router.push('login')
//     return
//   }
  // 获取状态码
  //   const status = err.response.status
  // switch (status) { // 其他情况补充处理
  //   case 500:
  //   case 400:
  //     //this.$message.error('服务器出现问题，请稍后重试！');
  //     router.push({
  //       name: 'login'
  //     })
  //     break
  //   case 401:
  //     //this.$message.error('访问资源未授权，请登陆后重试！');
  //      router.push({
  //       name: 'login'
  //     })
  //   break
  //   case 403:
  //     //this.$message.error('请求资源暂时不可用，请登陆后重试！');
  //     router.push({
  //       name: 'login'
  //     })
  //     break
  //   case 404:
  //     //this.$message.error('请求资源暂不存在，请稍后重试！');
  //     // router.push({
  //     //   name: 'login'
  //     // })
  //     break
  // }
  // loading.close();  //关闭loading

  if(err.response.status == '401') {
    ElementUI.Message({
      message: '登录已过期，请重新登录',
      type: 'warning'
    });
    localStorage.clear()
    router.push('/home')
    return
  }

  if (err.response.status == '403') {
    console.log(err.response.data);
    if (1011004 == err.response.data.code) {
      ElementUI.Message({
        message: '请先登录',
        type: 'warning'
      });
      localStorage.clear()
      // router.push('/login')
    } else {
      ElementUI.Message({
        message: '403',
        type: 'warning'
      });
    }

 }

  return Promise.reject(err)
})



export default instance;
