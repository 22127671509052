import store from '@/store/index.js'
import TIM from 'tim-js-sdk'
import TIMUploadPlugin from 'tim-upload-plugin'
// import genTestUserSig from '@/utils/debug/GenerateTestUserSig.js'

// 初始化 SDK 实例
const tim = TIM.create({
  SDKAppID: 1400737388, // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
})

// window.setLogLevel = tim.setLogLevel

// 无日志级别
tim.setLogLevel(0)

// 注册 cos
tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin })

let isReady = function (event) {
  console.log(
    event,
    '准备好'
  )
  if (event.name == 'sdkStateReady') {
    // 修改个人标配资料
    let promise = tim.updateMyProfile({
      nick: localStorage.getItem('account'),
      // avatar: 'http(s)://url/to/image.jpg',
      // gender: TIM.TYPES.GENDER_MALE,
      // selfSignature: '我的个性签名',
      // allowType: TIM.TYPES.ALLOW_TYPE_ALLOW_ANY
    })
    promise
      .then(function (imResponse) {
        console.log(imResponse.data) // 更新资料成功
      })
      .catch(function (imError) {
        console.warn('updateMyProfile error:', imError) // 更新资料失败的相关信息
      })

    // // 加入个人账号群
    // // 查询加入的群 该接口默认只会拉取这些资料：群类型、群名称、群头像、最后一条消息的时间。
    // const promise2 = tim.getGroupList()
    // promise2
    //   .then(function(imResponse) {
    //     console.log(imResponse.data.groupList, '群组列表') // 群组列表
    //     const groupList = imResponse.data.groupList
    //     // 直播群默认不查询
    //     if (!groupList.find(i => i.groupID === '@TGS#a2S4L6DJX')) {
    //       const promise3 = tim.joinGroup({ groupID: '@TGS#a2S4L6DJX', type: TIM.TYPES.GRP_AVCHATROOM })
    //       promise3.then(function(imResponse) {
    //         console.log(imResponse, '加入群成功')
    //       }).catch(function(imError) {
    //         console.log(imError, '加入群失败')
    //       })
    //     }
    //   })
    //   .catch(function(imError) {
    //     console.warn('getGroupList error:', imError) // 获取群组列表失败的相关信息
    //   })
  }
}
tim.on(TIM.EVENT.SDK_READY, isReady)

// // SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。如果想恢复使用，接入侧需调用 login 接口，驱动 SDK 进入 ready 状态
// let onSdkNotReady = function(event) {
//   console.log(event,'没准备好');
//   // 如果想使用发送消息等功能，接入侧需驱动 SDK 进入 ready 状态，重新调用 login 接口即可，如下所示：
//   const userID = localStorage.getItem('userID')
//   const userSig = genTestUserSig(userID).userSig
//   tim.login({userID: userID, userSig: userSig});
// };
// tim.on(TIM.EVENT.SDK_NOT_READY, onSdkNotReady);

// 监听会话列表
let onConversationListUpdated = function (event) {
  console.log(event.data, '会话列表') // 包含 Conversation 实例的数组
  // 只显示一对一消息,过滤运营：认证auth，评价eva，反馈feedback，企业：面试interview
  let operateMessage = ['auth','eva','feedback']
  let orgMessage = ['interview']
  let peopleList = []
  let operateList = []
  let orgList = []
  event.data.forEach(i => {
    // 如果是一对一消息
    if(i.type == 'C2C') {
      // 如果是自定义消息
      if(i.lastMessage.type == 'TIMCustomElem') {
        if(operateMessage.includes(i.lastMessage.payload.description)) {
          // 如果是运营系统消息
          operateList.push(i)
        } else if(orgMessage.includes(i.lastMessage.payload.description)) {
          // 如果是企业系统消息
          orgList.push(i)
        } else {
          // 如果是自定义简历之类
          peopleList.push(i)
        }
      } else {
        // 如果不是自定义消息
        peopleList.push(i)
      }
    }
  })
  store.commit('set_peopleList', peopleList)
  store.commit('set_operateList', operateList)
  store.commit('set_orgList', orgList)
  let sum = 0
  peopleList.forEach(i => {
    sum += i.unreadCount
  })
  if (sum > 0) {
    store.commit('set_isRedPoint', true)
  } else {
    store.commit('set_isRedPoint', false)
  }
  let operateSum = 0
  operateList.forEach(i => {
    operateSum += i.unreadCount
  })
  if (operateSum > 0) {
    store.commit('set_isOperatePoint', true)
  } else {
    store.commit('set_isOperatePoint', false)
  }
  let orgSum = 0
  orgList.forEach(i => {
    orgSum += i.unreadCount
  })
  if (orgSum > 0) {
    store.commit('set_isOrgRedPoint', true)
  } else {
    store.commit('set_isOrgRedPoint', false)
  }
}
tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, onConversationListUpdated)

// 监听新消息
let onMessageReceived = function (event) {
  // event.data - 存储 Message 对象的数组 - [Message]
  console.log(event.data, '收到消息')
  let data = event.data[0]
  let TIMUserId = store.state.TIMUserId
  if (TIMUserId && data.from == TIMUserId) {
    store.state.wordList.push(data)
  }
}
tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived)

// 网络状态发生改变
let onNetStateChange = function (event) {
  console.log(event, '网络状态')
  // v2.5.0 起支持
  // event.data.state 当前网络状态，枚举值及说明如下：
  // TIM.TYPES.NET_STATE_CONNECTED - 已接入网络
  // TIM.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
  // TIM.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
}
tim.on(TIM.EVENT.NET_STATE_CHANGE, onNetStateChange)

export default tim
