import request from "@/api/request"

export function login(data) {
  return request({
    method: 'post',
    url: '/jobUser/jobUser/login',
    data:data
  })
}


export function register(data) {
  return request({
    method: 'post',
    url: '/jobUser/jobUser/register',
    data:data
  })
}

export function getLoginUser(data) {
  return request({
    method: 'get',
    url: '/jobUser/jobUser/getLoginUser',
    params:data
  })
}

export function logout (data) {
  return request({
    url: '/jobUser/logout',
    method: 'get',
    params: data
  })
}

// 获取验证码
export function sendSmsCode (data) {
  return request({
    url: '/jobUser/oauth/sendSmsCode',
    method: 'get',
    params: data
  })
}

// 忘记密码
export function forgetPwd(data) {
  return request({
    method: 'post',
    url: '/jobUser/oauth/jobUser/forget',
    data:data
  })
}

// 修改密码
export function updatePwd(data) {
  return request({
    method: 'post',
    url: '/jobUser/jobUser/updatePwd',
    data:data
  })
}