import request from "@/api/request"

export function getOrg(data) {
  return request({
    method: 'get',
    url: '/jobUser/auth/getOrg',
    params:data
  })
}
// 上传图片回显
export function preview(data) {
  return request({
    method: 'get',
    url: `/jobUser/sysFileInfo/preview`,
    params: data,
    responseType:'blob',
  })
}
// 城市省份
export function getProvincial(data) {
  return request({
    method: 'get',
    // url: `/jobUser/getProvincial`,
    url: `/main/oauth/getProvincial`,
    params: data,
  })
}
//详细位置
export function getCity(data) {
  return request({
    method: 'get',
    // url: `jobUser/getCity`,
    url: `main/oauth/getCity`,
    params: data,
  })
}

//单位类型
export function tree(data) {
  return request({
    method: 'get',
    // url: '/main/jobPostType/tree',
    url: '/main/oauth/jobPostType/tree',
    params: data,
  })
}
