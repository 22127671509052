// 连接地址
//  const baseUrl = "http://120.46.176.211:9003/api";
const baseUrl = "https://www.zhengongjiang.cn/api";
// const baseUrl = "http://localhost:9003/api";
 const uploadAction = "http://121.36.46.11:9900/uploadfile";
//  图片加载失败后展示的图例
 const srcImgUrl = 'this.src="'+ require('@/assets/home/affiliated-img.png') + '"';
 function geTel(tel) {
  var reg = /^(\d{3})\d{4}(\d{4})$/;
  if(tel){
    return tel.replace(reg, "$1****$2");
  }else{
    return ''
  }
}
import { getDict } from "@/api/system/system";
import { getProvincial, getCity, getArea } from "@/api/index/index";

function getSomething1() {
  return new Promise(resolve => {
      getDict().then(res => {
             resolve(res)
        })
   })
}
function getSomething2() {
  return new Promise(resolve => {
    getProvincial().then(res => {
             resolve(res)
        })
   })
}
function getSomething3() {
  return new Promise(resolve => {
    getCity().then(res => {
             resolve(res)
        })
   })
}
function getSomething4() {
  return new Promise(resolve => {
    getArea().then(res => {
             resolve(res)
        })
   })
}
async function getDictInfo() {
  let res = await getSomething1()
  localStorage.setItem("dict", JSON.stringify(res.data));
  return res
}
async function getProvincialInfo() {
  let res = await getSomething2()
  localStorage.setItem("provincialList", JSON.stringify(res.data));
  return res
}
async function getCityInfo() {
  let res = await getSomething3()
  localStorage.setItem("cityList", JSON.stringify(res.data));
  return res
}
async function getAreaInfo() {
  let res = await getSomething4()
  localStorage.setItem("areaList", JSON.stringify(res.data));
  return res
}

function handTK(tkString) {
  let s1 = tkString.match(/\(()\)/g);
  if (s1 != null && s1.length > 0) {
    let tkArr = [];
    let s = tkString.split(s1[0]);
    s.map((data, index) => {
      let obj = {};
      obj.question = data;
      if (s1[index]) {
        obj.questionType = s1[index];
        obj.input = "";
        obj.flag = "0";
      }
      tkArr.push(obj);
    });
    return tkArr;
  } else {
    let s2 = tkString.match(/\((.+?)\)/g);
    if (s2 != null && s2.length > 0) {
      let tkArr = [];
      let s = tkString.split(s2[0]);
      s.map((data, index) => {
        let obj = {};
        obj.question = data;
        if (s2[index]) {
          obj.questionType = s2[index];
          obj.input = "";
          obj.flag = "0";
        }
        tkArr.push(obj);
      });
      return tkArr;
    } else {
      return [];
    }
  }
}

export default {
  baseUrl,uploadAction,srcImgUrl,geTel,getProvincialInfo,getCityInfo,getAreaInfo,getDictInfo,handTK
};
