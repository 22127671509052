import request from "@/api/request"

export function getDict(data) {
  return request({
    method: 'get',
    // url: '/jobUser/sysDictType/tree',
    url: '/jobUser/oauth/sysDictType/tree',
    params:data
  })
}
