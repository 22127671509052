import request from "@/api/request"
export function addTeam(data) {
  return request({
    method: 'post',
    url: '/main/jobTim/add',
    data:data
  })
}
export function detailTeam(data) {
  return request({
    method: 'get',
    url: '/main/jobTim/detailOne',
    params:data
  })
}
