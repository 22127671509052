import request from "@/api/request"


// 轮播图
export function rotationChart (parameter) {
  return request({
    url: '/main/oauth/rotationChart/list',
    // url: '/jobUser/oauth/rotationChart/list',
    method: 'get',
    params: parameter
  })
}

// 栏目
export function jobNewsColumnTree (parameter) {
  return request({
    url: '/main/oauth/jobNewsColumn/tree',
    // url: '/jobUser/oauth/jobNewsColumn/tree',
    method: 'get',
    params: parameter
  })
}

// 新闻
export function jobNews (parameter) {
  return request({
    // url: '/main/oauth/jobNews/page',
    url: '/jobUser/oauth/pageThreeImgNews',
    // url: '/jobUser/oauth/jobNews/page',
    method: 'get',
    params: parameter
  })
}
// 新闻详情
export function detail (parameter) {
  return request({
    url: '/main/oauth/jobNews/detail',
    // url: '/jobUser/oauth/jobNews/page',
    method: 'get',
    params: parameter
  })
}

// 企业展示、热搜榜单、核心企业、热招岗位
export function jobOrgAllPage (parameter) {
  return request({
    url: '/main/oauth/jobOrgAll/page',
    method: 'get',
    params: parameter
  })
}

//优秀个人
export function jobEvaluation(parameter) {
  return request({
    method: 'get',
    url: '/main/oauth/jobEvaluation/pageExcellent',
    params:parameter
  })
}

//考证及培训
export function jcCoursePage(parameter) {
  return request({
    method: 'get',
    url: '/course/oauth/jcCourse/page',
    params:parameter
  })
}

//培训
export function jcTrainingPage(parameter) {
  return request({
    method: 'get',
    url: '/course/oauth/jcTraining/page',
    params:parameter
  })
}

//招聘会
export function jobFairApplyList(parameter) {
  return request({
    method: 'get',
    url: '/main/oauth/jobFair/applyList',
    params:parameter
  })
}

//往期招聘会
export function  jobFairPage(parameter)  {
  return  request({
    method: 'get',
    url: '/main/oauth/jobFair/page',
    params:parameter
  })
}

//招聘会详情
export  function jobFairDetail(parameter)  {
  return  request({
    method: 'get',
    url: '/main/oauth/jobFair/detail',
    params:parameter
  })
}

//招聘会报名
export  function userApply(parameter)  {
  return  request({
    method: 'post',
    url: '/main/jobFairUserApply/userApply',
    params:parameter
  })
}



//找人才
export function queryJobInfoPage (data) {
  return request({
    method: 'get',
    url: '/job/oauth/jobInfo/queryJobInfoPage',
    params: data
  })
}

//上链滚动
export function newEvaluation (parameter) {
  return request({
    method: 'get',
    url: '/main/oauth/jobEvaluation/newEvaluation',
    params: parameter
  })
}

//找企业
export function findJobOrg (parameter) {
  return request({
    method: 'get',
    url: '/job/oauth/jobOrgSearch',
    params: parameter
  })
}

//行业
export function jobPostType (parameter) {
  return request({
    method: 'get',
    url: '/main/oauth/jobPostType/tree',
    params: parameter
  })
}

//公告
export function systemNotice (parameter) {
  return request({
    method: 'get',
    url: '/jobUser/jobInform/page',
    params: parameter
  })
}
