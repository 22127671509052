import request from "@/api/request"


// 获取省份
export function getProvincial(data) {
  return request({
    method: 'get',
    url: '/jobUser/oauth/getProvincial',
    // url: `/main/oauth/getProvincial`,
    params:data
  })
}

// 获取城市
export function getCity(data) {
  return request({
    method: 'get',
    url: '/jobUser/oauth/getCity',
    // url: `main/oauth/getCity`,
    params:data
  })
}

// 获取区域
export function getArea(data) {
  return request({
    method: 'get',
    url: '/jobUser/oauth/getArea',
    // url: '/main/oauth/getArea',
    params:data
  })
}

// 关于我们
export function jobOrgDetail(data) {
  return request({
    method: 'get',
    url: '/main/oauth/jobOrgDetail',
    params:data
  })
}


// 根据id查询区域
export function getAreaById(data) {
  return request({
    method: 'get',
    url: '/job/oauth/getAreaById',
    params:data
  })
}


// 增加访问量
export function readAdd(parameter) {
  return request({
    method: 'post',
    url: '/jobUser/oauth/readAdd?type=pc',
    params:parameter
  })
}


// 消息
export function getUserMsg(data) {
  return request({
    method: 'get',
    url: '/jobUser/getUserMsg',
    params:data
  })
}